<template>
  <!-- eslint-disable vue/no-v-html -->
  <div>
    <template v-if="!loadingLandingPage && dataWidget !== null">
      <main role="main" class="flex-shrink-0 mb-5">
        <div class="header-row">
          <div class="container">
            <div class="row">
              <div class="col">
                  <h1 class="text-white">{{ dataWidget.business.business_name }}</h1>
                  <div class="d-flex text-white align-items-center">
                    <div class="star-container d-flex">
                      <div class="rating-count">
                        {{ reviewAverage }}
                      </div>
                      <div class="star-count mr-2">
                        <div class="rating-upper" :style="'width:' + starWidth + '%'"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div>
                        <div class="rating-lower"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div>
                      </div>
                    </div>

                    <div class="review-count">
                      {{ reviewTotal }} Reviews
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container bg-white">
          <div class="row">
            <div class="col my-4 font-weight-bold">
                <h2 class="text-center mb-3">{{dataWidget.gate_body_title}}</h2>
                <div class="main-text text-center">
                  {{dataWidget.gate_body_text}}
                </div>
              <div class="thumbs mb-5">
                <div class="d-flex justify-content-center">
                  <div class="thumbs-up text-center py-4 px-4">
                    <a :href="reviewLink">
                      <svg style="width:110px;height:110px;color:#599b5a" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M23,10C23,8.89 22.1,8 21,8H14.68L15.64,3.43C15.66,3.33 15.67,3.22 15.67,3.11C15.67,2.7 15.5,2.32 15.23,2.05L14.17,1L7.59,7.58C7.22,7.95 7,8.45 7,9V19A2,2 0 0,0 9,21H18C18.83,21 19.54,20.5 19.84,19.78L22.86,12.73C22.95,12.5 23,12.26 23,12V10M1,21H5V9H1V21Z" />
                      </svg>
                      <span class="d-block pt-1">{{dataWidget.gate_good_label}}</span>
                    </a>
                  </div>
                  <div class="thumbs-down text-center py-4 px-4">
                    <button @click="showNegativeReviewModal = true">
                      <svg style="width:110px;height:110px;color:#e85f5f" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,15H23V3H19M15,3H6C5.17,3 4.46,3.5 4.16,4.22L1.14,11.27C1.05,11.5 1,11.74 1,12V14A2,2 0 0,0 3,16H9.31L8.36,20.57C8.34,20.67 8.33,20.77 8.33,20.88C8.33,21.3 8.5,21.67 8.77,21.94L9.83,23L16.41,16.41C16.78,16.05 17,15.55 17,15V5C17,3.89 16.1,3 15,3Z" />
                      </svg>
                      <span class="d-block pt-1">{{dataWidget.gate_bad_label}}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-row row">
            <div class="col">
              {{dataWidget.business.address }}, {{ dataWidget.business.city }}, {{dataWidget.business.state}} {{ dataWidget.business.zip }} | {{ dataWidget.business.business_phone }}
            </div>
          </div>
        </div>
      </main>
      <b-modal v-model="showNegativeReviewModal" modal-class="neg-feedback" bo title-html="" :hide-footer="true" :hide-header="true" :hide-header-close="true">
        <h5 class="text-center">Please leave your feedback below</h5>
        <TextInput v-model="negativeReviewForm.name" label="Name" />
        <TextInput v-model="negativeReviewForm.email" label="Email" />
        <label>Feedback</label>
        <BTextarea v-model="negativeReviewForm.feedback" rows="6" />
        <button :disabled="negativeFormWorking" class="btn btn-primary btn-sm mt-4 rounded-lg w-100 py-2" @click="submitNegativeExperience">
          <b-spinner v-if="negativeFormWorking" small class="mr-2"/> Submit</button>
        <div v-if="submissionMessage !== ''" class="feedback">{{ submissionMessage }}</div>
        <div class="skip-gate">If you prefer to just leave a review, <a :href="reviewLink">click here</a></div>
      </b-modal>
    </template>
    <div v-else-if="status === 404">
      <h1 class="text-center">Not Found</h1>
    </div>
    <div v-else class="text-center mt-4">
      <b-spinner></b-spinner>
    </div>
  </div>
</template>

<script>

export default {
  components:{

  },
  props:{
    widget: {
      type: Object,
      default:null
    }
  },
  data() {
    return {
      loadingLandingPage: false,
      showNegativeReviewModal:false,
      negativeFormWorking:false,
      dataWidget:this.widget,
      submissionMessage:'',
      status:200,
      negativeReviewForm:{
        name:'',
        email:'',
        feedback:'',
        id:'',
        request_key:'rwg_pass'
      }
    }
  },
  metaInfo () {
    return {
      title: 'Review ' + this.dataWidget?.business.business_name,
      meta: [
        { name: 'og:title', content: 'Review ' + this.dataWidget?.business.business_name },
      ],

    }
  },
  computed: {
    reviewAverage(){
      if(this.dataWidget.business_rating){
        return this.dataWidget.business_rating.avg_review.toFixed(1);
      }else {
        const totalRating = this.dataWidget.reviews.reduce((total, next) => total + next.review_rating, 0);
        return (totalRating / this.dataWidget.reviews.length).toFixed(1);
      }
    },
    starWidth(){
      if(this.dataWidget.business_rating){
        return (this.dataWidget.business_rating.avg_review / 5) * 100;
      }else{
        return (this.reviewAverage / 5) * 100;
      }
    },
    reviewTotal(){
      if(this.dataWidget.business_rating){
        return this.dataWidget.business_rating.total_reviews;
      }else{
        return this.dataWidget.reviews.length;
      }
    },
    reviewLink(){
      const placeId = this.dataWidget.business.aux_place_id ?? this.dataWidget.business.place_id;
      return 'https://search.google.com/local/writereview?placeid=' + placeId;
    }
  },

  mounted() {
    if(this.widget === null) {
      this.getWidget();
    }else{
      this.dataWidget = this.widget;
    }
  },

  methods: {
    getWidget() {
      this.loadingLandingPage = true;
      this.$store
          .dispatch('landingPage/getReviewGate', this.$route.params.uuid)
          .then((res) => {
            this.status = 200;
            this.loadingLandingPage = false
            this.dataWidget = res;
          })
          .catch((error) => {
            if(error.response.status){
              this.status = error.response.status;
            }
            this.loadingLandingPage = false
          })
    },
    submitNegativeExperience(){
      this.negativeReviewForm.id = this.dataWidget.business.id;
      this.negativeFormWorking = true;
      this.submissionMessage = '';
      this.$store
          .dispatch('landingPage/postNegativeExperience', this.negativeReviewForm)
          .then((res) => {
            this.negativeFormWorking = false;
            if(res.success){
              this.submissionMessage = res.message;
              this.negativeReviewForm.name = '';
              this.negativeReviewForm.email = '';
              this.negativeReviewForm.feedback = '';
            }
          })
          .catch((error) => {
            console.log(error);
            this.negativeFormWorking = false
          })
    }
  },
}
</script>
<style>
@import "https://fonts.googleapis.com/css2?family=Raleway&display=swap";
</style>
<style lang="scss" scoped>
.container{
  max-width: 810px;
}
  .header-row .container{
    background: #4472c4;
    padding:30px;
    h1{
      margin-top: 0;
      margin-bottom: 5px;
      font-weight: bold;
    }
  }
  .star-container {
    font-size: 15px;
    background: #538df4;
    border-radius:20px;
    align-items: center;
    padding: 0 3px 8px 12px;
    margin-right: 10px;
    .rating-count{
      margin-bottom: -9px;
      font-weight: bold;
      margin-right: 5px;
    }
    .star-count {
      display: inline-block;
      unicode-bidi: bidi-override;
      color: #888888;
      font-size: 23px !important;
      span {
        font-size: 23px !important;
        &:last-child{
          padding-right: 0;
        }
      }

      height: 25px;
      width: auto;
      margin: 0;
      position: relative;
      padding: 0;

      .rating-upper {
        color: #fff;
        padding: 0;
        position: absolute;
        z-index: 1;
        display: flex;
        top: 0;
        left: 0;
        overflow: hidden;
      }

      .rating-lower {
        padding: 0;
        display: flex;
        z-index: 0;
      }
    }
  }
  .review-count{
    font-weight: bold;
    font-size: 15px;
  }
  main{
    h2{
      color: #3b3c3c;
    }
    .main-text{
      max-width:570px;
      width: 100%;
      margin: 0 auto;
      font-size: 18px;
      color: #7b7c7b;
    }
    .thumbs{
      span{
        color: #7b7c7b;
      }
      > .d-flex{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        span{
          max-width: 300px;
          word-break: break-all;
        }
      }
      .thumbs-up{
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }
      .thumbs-down{
        flex: 1;
        display: flex;
        button{
          appearance: none;
          background:none;
          border:0;
          font-weight: bold;
          span{
            max-width: 300px;
            word-break: break-all;
          }
        }
      }
    }
  }
  .footer-row{
    background: #d9d9d9;
    text-align: center;
    padding: 20px;
    color: #8d8d8d;
    font-weight: bold;
  }
  .neg-feedback {
    .modal-content{
      border-radius: 0;
      border: 0;
    }
    .feedback{
      text-align: center;
      margin-top: 10px;
      color: green;
    }
    .skip-gate {
      color: #7b7b7b;
      background: #d9d9d8;
      text-align: center;
      padding: 5px 10px;
      margin: 30px -16px -16px -16px;
      font-size: 14px;
      border-bottom: 2px solid #8a8a8b;

      a {
        color: #538ef4;
      }
    }
  }
  @media screen and (max-width:640px){
    .header-row .container {
      padding: 15px;
      h1 {
        font-size: 18px;
      }
      .star-container{
        font-size: 13px;
        .star-count{
          font-size: 18px !important;
          height: 20px;
          span{
            font-size: 18px !important;
          }
        }
      }
      .review-count{
        padding-top: 2px;
        font-size: 13px;
      }
    }
  main {
    h2{
      font-size: 22px;
    }
    .main-text{
      font-size: 14px;
    }
    .thumbs{
      span{
        font-size: 12px;
      }
    }
    .footer-row{
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 12px;
      padding: 10px 0;
      font-size: 10px;
      .col{
        padding: 0 5px;
      }
    }
  }
}
@media screen and (max-width:400px){
  main{
    .thumbs{
      .thumbs-up{
        justify-content: flex-start;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .thumbs-down{
        justify-content: flex-end;
        padding-right: 0 !important;
        padding-left: 0 !important;

      }
    }
  }
}
</style>
<style>
@media screen and (max-width:640px) {
  body {
    background: #fff;
  }
}
</style>
